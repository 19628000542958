<template>
  <div class="auth">
    <div class="auth-header">
      <div class="auth-header__logos">
        <a href="https://wbgrants.mininnovation.uz/" class="auth-header__logo">
          <img src="@/assets/images/logo.svg" alt="logo_uzb" />
          <span>{{ $t("SidebarLogo") }}</span>
        </a>
        <a
          href="https://www.worldbank.org/"
          class="auth-header__logo"
          target="_blank"
        >
          <img src="@/assets/images/world.png" alt="logo_world" />
        </a>
        <div class="header-lang">
          <b-dropdown id="dropdown-3" :text="currentLang">
            <b-dropdown-item
              v-for="(lang, index) in langs"
              :key="index"
              @click="changeLang(lang.key)"
              >{{ lang.title }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>
    <div class="auth-main">
      <authAdminLogin />
    </div>
    <div class="auth-footer">
      <span class="dev"
        >{{ $t("Developer") }}:
        <a href="https://osg.uz">Online Service Group</a></span
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpenAdminLogin: true,

      langs: [
        { title: "Русский", key: "ru" },
        { title: "Engilish", key: "en" },
        { title: "O'zbekcha", key: "uz" },
      ],
      currentLang: this.$i18n.locale,
    };
  },
  mounted() {
    let locale = localStorage.getItem("locale");
    locale && (this.currentLang = locale);
    locale && (this.$i18n.locale = locale);
    !locale && localStorage.setItem("locale", "ru");
  },
  methods: {
    changeLang(val) {
      this.$i18n.locale = val;
      this.currentLang = val;
      localStorage.setItem("locale", val);
      // this.$router.go();
    },
  },
};
</script>

<style>
</style>
